import { parseISO } from "date-fns";
import { graphql, Link } from "gatsby";
import * as React from "react";
import styled from "styled-components";
import {
  BlogCard,
  Column,
  CurvedSectionDividerGlow,
  Grid,
  Gutter,
  HelmetSeo,
  Layout,
  PageMaxWidth,
  Section,
  Text,
  Visibility,
} from "../components";
import { FeaturedPost } from "../components/blog";
import { Color, Dimen } from "../constants";
import { calculateReadTime, extractBlogSnippet } from "../prismic-tools";
import { unwrapConnection } from "../util";

type Props = {
  data: GatsbyTypes.BlogIndexQuery;
  pageContext: any;
};

export const query = graphql`
  query BlogIndex($skip: Int!, $limit: Int!) {
    prismicBlogIndexPage {
      data {
        featured_post {
          document {
            __typename
            ... on PrismicBlogPost {
              ...FeaturedPost
            }
          }
        }

        seo_title
        seo_description
        share_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
      }
    }

    allPrismicBlogPost(
      skip: $skip
      limit: $limit
      sort: { fields: [data___publish_date], order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            title
            publish_date
            featured_image {
              alt
              dimensions {
                width
                height
              }
              url
            }
            ...ToolsBlogContent
          }
        }
      }
    }
  }
`;

export default function BlogIndexTemplate({ data, pageContext }: Props) {
  const page = data.prismicBlogIndexPage?.data;
  if (!page) throw new Error("Page missing");

  const postNodes = unwrapConnection(data.allPrismicBlogPost);

  let featuredPostNode = postNodes[0];
  const document = data.prismicBlogIndexPage?.data?.featured_post?.document;
  if (document?.__typename === "PrismicBlogPost") {
    featuredPostNode = document;
  }

  const { currentPageNumber } = pageContext;
  const helmetSeoProps = {
    ...page,
    ...(currentPageNumber > 1 && {
      seo_title: `${page.seo_title} | Page ${currentPageNumber}`,
    }),
  };

  return (
    <Layout>
      <HelmetSeo {...helmetSeoProps} />

      {currentPageNumber === 1 ? (
        <>
          <Section background="light4">
            <PageMaxWidth>
              <Gutter size={3.5} md={2} />
              <FeaturedPost postNode={featuredPostNode} />
            </PageMaxWidth>
          </Section>
          <CurvedSectionDividerGlow
            position="bottom"
            glowColor="light4"
            backColor="light1"
          />
        </>
      ) : (
        <Gutter size={2} />
      )}

      <Section>
        <PageMaxWidth>
          <Gutter />
          <Grid>
            {postNodes.map((postNode) => {
              const post = postNode.data;
              if (!post) return null;
              return (
                <Column size={4} key={postNode.uid}>
                  <BlogCard
                    to={`/blog/${postNode.uid}`}
                    image={post.featured_image}
                    date={
                      post.publish_date
                        ? parseISO(post.publish_date)
                        : undefined
                    }
                    title={post.title}
                    description={extractBlogSnippet(post)}
                    readTime={calculateReadTime(post)}
                  />
                  <Visibility hideBelow="md">
                    <Gutter />
                  </Visibility>
                </Column>
              );
            })}
          </Grid>
          <Gutter size={2} />

          <StyledPageLinks>
            {pageContext.previousPagePath ? (
              <Link to={pageContext.previousPagePath}>
                <svg className="mirror">
                  <use xlinkHref="#svg-arrow-right" />
                </svg>
                <Text typeface="caption">Previous Page</Text>
              </Link>
            ) : (
              <span>
                <svg className="mirror">
                  <use xlinkHref="#svg-arrow-right" />
                </svg>
                <Text typeface="caption">Previous Page</Text>
              </span>
            )}

            <div className="divider" />

            {pageContext.nextPagePath ? (
              <Link to={pageContext.nextPagePath}>
                <Text typeface="caption">Next Page</Text>
                <svg>
                  <use xlinkHref="#svg-arrow-right" />
                </svg>
              </Link>
            ) : (
              <span>
                <Text typeface="caption">Next Page</Text>
                <svg>
                  <use xlinkHref="#svg-arrow-right" />
                </svg>
              </span>
            )}
          </StyledPageLinks>
          <Gutter size={3} md={2} />
        </PageMaxWidth>
      </Section>
    </Layout>
  );
}

const StyledPageLinks = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;

  > a,
  > span {
    height: 42px;
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      fill: ${Color.dark4};
      margin-left: 6px;
      width: 13px;
      height: 12px;
      transition: all 150ms;

      &.mirror {
        transform: rotate(180deg);
      }
    }
  }

  > span {
    opacity: 0.25;
  }

  > a {
    span {
      transition: color 0.25s;
    }

    &:hover {
      span {
        color: ${Color.blue1};
      }

      svg {
        fill: ${Color.blue1};
        transform: translateX(7px);

        &.mirror {
          transform: translateX(-7px) rotate(180deg);
        }
      }
    }
  }

  > .divider {
    width: 1px;
    background-color: ${Color.orange1};
    margin: 0 ${Dimen.gutterWidth / 2}px;
  }
`;
