type Maybe<T> = T | undefined;

type Connection<T> = {
  edges: Maybe<
    ReadonlyArray<
      Maybe<{
        node: T;
      }>
    >
  >;
};

export function unwrapConnection<T>(connection: Connection<T>): T[] {
  const edges = (connection?.edges || []).filter(Boolean);
  return edges.map((edge) => edge?.node).filter(Boolean) as T[];
}

export function unwrapFirst<T>(connection: Connection<T>): T {
  const first = unwrapConnection(connection)[0];
  if (!first) {
    throw new Error("Called unwrapFirst on a connection with no edges");
  }
  return first;
}
