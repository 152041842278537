import { format, parseISO } from "date-fns";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import * as React from "react";

import {
  calculateReadTime,
  extractBlogSnippet,
  fluidImage,
} from "../../prismic-tools";
import { Column, Grid, Gutter, MaxWidth, PageMaxWidth, Text } from "..";
import styled from "styled-components";
import { Color } from "../../constants";

type Props = {
  postNode: GatsbyTypes.FeaturedPostFragment;
};

export const query = graphql`
  fragment FeaturedPost on PrismicBlogPost {
    uid
    data {
      title
      publish_date
      featured_image {
        alt
        dimensions {
          width
          height
        }
        url
      }
      ...ToolsBlogContent
    }
  }
`;

export default function FeaturedPost({ postNode }: Props) {
  const post = postNode.data;
  if (!post) return null;

  return (
    <Grid alignColumns="stretch" justifyColumns="space-between" reverseColumns>
      <Column size={6}>
        <Gutter size={2} md={0} />
        <MaxWidth width={528}>
          <StyledImageBorder>
            <Img fluid={fluidImage(post.featured_image, 528)} />
          </StyledImageBorder>
        </MaxWidth>
      </Column>
      <Column size={5}>
        <StyledAreaMain>
          <div style={{ flex: 1 }}>
            <Text as="p" typeface="caption">
              {post.publish_date &&
                format(parseISO(post.publish_date), "MMMM Y")}
            </Text>
            <Gutter md={0.5} />
            <Text as="h2" bold typeface="h3">
              {post.title}
            </Text>
            <Gutter />
            <Text>{extractBlogSnippet(post)}…</Text>
            <Gutter />
          </div>
          <StyledBottomRow>
            <div>
              <Text typeface="caption">Blog</Text>
              <Gutter />
              <Text typeface="caption">{calculateReadTime(post)} min read</Text>
            </div>
            <StyledLink to={`/blog/${postNode.uid}`}>
              <Text typeface="caption" color="blue1">
                Read More
              </Text>
              <svg>
                <use xlinkHref="#svg-arrow-right" />
              </svg>
            </StyledLink>
          </StyledBottomRow>
        </StyledAreaMain>
      </Column>
    </Grid>
  );
}

const StyledAreaMain = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledBottomRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: -5px 0;

  > div {
    display: flex;
    align-items: center;
  }
`;

const StyledLink = styled(Link)`
  padding: 5px 0;

  svg {
    fill: ${Color.blue1};
    margin-left: 6px;
    width: 13px;
    height: 12px;
    transition: transform 150ms;
  }

  &:hover {
    svg {
      transform: translateX(7px);
    }
  }
`;

const StyledImageBorder = styled.div`
  border-radius: 8px;
  overflow: hidden;
`;
